import React, { useEffect, useState, use } from 'react';
import { Route, Routes, useRoutes } from 'react-router-dom';
import MortgageDetail from '../../Pages/Hadmin/MortgageDetail/MortgageDetail';
import { Spin, Affix, Button } from 'antd';
import authClient from '../../Auth/Auth';
import Login from '../../Pages/auth/Login';
import Layout from '../Layout/Layout';
import Dashboard from '../../Pages/Hadmin/dashboard/Dashboard';
import MortgagesHistory from '../../Pages/Hadmin/Operations/MortgagesHistory';
import { useDispatch, useSelector } from 'react-redux';
import BudgetSVH from '../../Pages/Hadmin/MortgageDetail/Components/BudgetsSVH/BudgetsSVH';
import LayoutMobile from '../Layout/LayoutMobile';
import { isMobile } from 'react-device-detect';
import DashboardMobile from '../../Pages/Hadmin/dashboard/DashboardMobile';
import UpdateExcel from '../../Pages/Hadmin/callcenter/UpdateExcel';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
	fetchTaskExpired,
	fetchTaskList,
	fetchTaskPerWeeks,
	updateNotificationBoolean
} from '../../store/task/actions';
import { openNotification } from '../ModalsAndAlerts/taskNotification';
import GeneratorURL from '../../Pages/Hadmin/MortgageDetail/Components/bank/pageEmailbank/GenereratorURL';
import PromoterMobile from '../ModalsAndAlerts/PromoterMobile';
import { useAnalytics } from 'use-analytics';

import { cyclops } from '@hadmin/cyclops';
import env from '../../environment';
import Axios from 'axios';
import moment from 'moment';

const TaskRouter = React.lazy(() => import('./routers/tasks'));
const OperationsRouter = React.lazy(() => import('./routers/operations'));
const SearchRouter = React.lazy(() => import('./routers/search'));
const CertificatesRouter = React.lazy(() => import('./routers/certificates'));
const PropertiesRouter = React.lazy(() => import('./routers/properties'));
const PromoterRouter = React.lazy(() => import('./routers/promoter'));

function Navigation() {
	const [loading, setLoading] = useState(true);
	const [secureRoutes, setSecureRoutes] = useState([]);
	const [insecureRoutes, setInsecureRoutes] = useState([]);
	const allTask = useSelector((state) => state.task.setDataTask);
	const updateNotification = useSelector((state) => state.task.setDataNotification);
	const days = useSelector((state) => state.task.setDays);
	const dispatch = useDispatch();
	const analytics = useAnalytics();
	const tasksExpired = useSelector((state) => state.task.setDataTaskExpired);

	useEffect(() => {
		// send page view on route change
		analytics.page();
	}, [analytics, location]);

	useEffect(() => {
		(async () => {
			await authClient.refreshProfile();
			setLoading(false);
		})();
		setInterval(() => {
			dispatch(fetchTaskList(undefined));
			dispatch(fetchTaskExpired(authClient.getToken(), undefined));

			dispatch(fetchTaskPerWeeks(days));
			dispatch(updateNotificationBoolean(true));
		}, 600000);
	}, []);

	useEffect(() => {
		if (authClient.isAuthenticated() && updateNotification && tasksExpired) {
			openNotification(tasksExpired, dispatch);
		}
	}, [tasksExpired, updateNotification]);

	useEffect(async () => {
		const blue = await import('@hadmin/blueprints');
		const realestates = await import('@hadmin/realestateB2B');
		const banks = await import('@hadmin/banks');
		const clusters = await import('@hadmin/clusters');
		const reports = await import('@hadmin/reports');
		const preregister = await import('@hadmin/preregister');

		cyclops.addPlugin(blue.default);
		cyclops.addPlugin(realestates.default);
		cyclops.addPlugin(banks.default);
		cyclops.addPlugin(clusters.default);
		cyclops.addPlugin(reports.default);
		cyclops.addPlugin(preregister.default);

		cyclops.setAuth({
			...authClient.getProfile(),
			token: authClient.getToken()
		});

		cyclops.setEnv(env);

		const secureRoutes = cyclops.getRootRoutes(true);
		const insecureRoutes = cyclops.getRootRoutes(false);

		setSecureRoutes(secureRoutes);
		setInsecureRoutes(insecureRoutes);
		dispatch(fetchTaskExpired(authClient.getToken(), undefined));
	}, [authClient.isAuthenticated()]);

	const loader = (
		<div style={{ textAlign: 'center', height: '100vh' }}>
			<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
		</div>
	);

	if (loading) {
		return loader;
	}

	const privateRoutes = secureRoutes.map((route) => (
		<Route
			path={`${route}*`}
			Component={() => {
				const frontRoutes2 = cyclops.getFrontRoutes(route);
				let element = useRoutes(frontRoutes2);

				return element;
			}}
		/>
	));

	return (
		<React.Suspense fallback={loader}>
			<div>
				{authClient.isAuthenticated() ? (
					<>
						{isMobile ? (
							<LayoutMobile>
								<Routes>
									<Route path="/" element={<DashboardMobile />} />
									<Route path="/operations/mortgages/:mortgageId" element={<MortgageDetail />} />
									<Route
										path="/promoter-loan/operations/:mortgageId"
										element={<PromoterMobile />}
									/>
								</Routes>
							</LayoutMobile>
						) : (
							<Layout>
								<Routes>
									{privateRoutes}
									<Route path="/" element={<Dashboard />} />
									<Route path="/operations*" element={<OperationsRouter />} />
									<Route path="/management*" element={<TaskRouter />} />
									<Route path="/search*" element={<SearchRouter />} />
									<Route path="/certificates*" element={<CertificatesRouter />} />
									<Route path="/history" element={<MortgagesHistory />} />
									<Route
										path="/generator-url-link-message/:mortgageId"
										element={<GeneratorURL />}
									/>
									<Route path="/properties" element={<PropertiesRouter />} />
									<Route path="/promoter-loan*" element={<PromoterRouter />} />
									<Route path="/budgetsSVH" element={<BudgetSVH />} />
									<Route path="/callcenter" element={<UpdateExcel />} />
								</Routes>
							</Layout>
						)}
					</>
				) : (
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="*" element={<Login />} />
					</Routes>
				)}
			</div>
			{authClient.isAuthenticated() ? (
				<Affix style={{ float: 'right', marginRight: 10 }} offsetBottom={10}>
					<Button
						href="https://gibobs.atlassian.net/servicedesk/customer/portal/1"
						target="_blank"
						type="primary"
						icon={<QuestionCircleOutlined />}
						shape="round">
						Ayuda
					</Button>
				</Affix>
			) : undefined}
		</React.Suspense>
	);
}
export default Navigation;
