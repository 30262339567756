import React, { useEffect, useRef, useState } from 'react';
import '../../../styles/Mortgages.css';
import axios from 'axios';
import env from '../../../environment';
import {
	Card,
	Col,
	Layout,
	Row,
	Button,
	Modal,
	Form,
	Input,
	Select,
	Typography,
	Pagination
} from 'antd';
import authClient from '../../../Auth/Auth';
import { NavLink } from 'react-router-dom';
import MyBreadcrumb from '../../../Components/ModalsAndAlerts/MyBreadcrumb';
import {
	CheckOutlined,
	CalendarOutlined,
	ArrowUpOutlined,
	PauseOutlined,
	ArrowDownOutlined,
	BellOutlined,
	SearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import TextArea from 'antd/lib/input/TextArea';
import ModalCreateTasks from '../../../Components/ModalsAndAlerts/ModalCreateTasks';
import ModalReScheduleTask from '../../../Components/ModalsAndAlerts/ModalReScheduleTask';
import ModalFinishTask from '../../../Components/ModalsAndAlerts/ModalFinishTask';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import CalendarComponent from '../Calendar/CalendarComponent';
import { useDispatch } from 'react-redux';
import {
	actions,
	fetchTaskPerWeeks,
	fetchTaskList,
	updateNotificationBoolean
} from '../../../store/task/actions';
import ModalsIncludesComponent from '../../../Components/ModalsAndAlerts/ModalsIncludesComponent';
import dayjs from 'dayjs';
import { allAnalyst } from '../../../store/mortgages/actions';

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;

function Dashboard(props) {
	const [showModalActionFin, setShowModalActionFin] = useState(false);
	const [showModalActionDelete, setShowModalActionDelete] = useState(false);
	const [showModalActionAsing, setShowModalActionAsing] = useState(false);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [maxValue, setMaxValue] = useState(10);
	const [minValue, setMinValue] = useState(0);
	const [profile, setProfile] = useState();
	const [value, setValue] = useState(0);
	const [page, setPage] = useState(1);
	const [currentDate, setCurrentDate] = useState(moment().format('YYYY/MM/DD'));
	const days = useSelector((state) => state.task.setDays);
	const [taskDetails, setTaskDetails] = useState();
	const [showModalCreate, setShowModalCreate] = useState(false);
	const [agentsRisk, setAgentsRisk] = useState([]);
	let tasksObj = useSelector((state) => state.task.setDataTask);
	const allTaskWeek = useSelector((state) => state.task.setDataTaskPerWeek);
	const [filterType, setFilterType] = useState('');
	const [filterPriority, setFilterPriority] = useState('');
	const [filterAsigneed, setFilterAsigneed] = useState('');
	const [filterDate, setFilterDate] = useState('');
	const [searchByDescription, setSearchByDescription] = useState('');
	const userToken = useSelector((state) => state.auth.token);
	const [formDelete] = Form.useForm();
	const [formFinish] = Form.useForm();
	const dispatch = useDispatch();
	let operationIdModal = undefined;
	const [visibleConfirm, setVisibleConfirm] = useState(false);
	const [visible, setVisible] = useState(false);
	const [bankRequest, setBankRequest] = useState(undefined);
	const [visibleConfirmComponent, setVisibleConfirmComponent] = useState(false);
	const [form] = Form.useForm();
	const [filterTaskType, setFilterTaskType] = useState('');
	const [filterTaskStage, setFilterTaskStage] = useState('');
	const [searchParams, setSearchParams] = useState({
		textSearch: undefined,
		withNoTaskType: undefined,
		type: undefined,
		stage: undefined,
		priority: undefined,
		isAssignedToPrerisk: undefined,
		page: undefined,
		limit: 10
	});
	const initDelete = {
		note: undefined,
		taskIdDete: undefined,
		hour: moment('21:00', 'HH:mm')
	};

	const initDelete2 = {
		date: undefined,
		taskIdDete: undefined,
		operation: undefined,
		type: undefined,
		name: undefined,
		description: undefined,
		assigned: undefined,
		agentId: undefined
	};

	const timeoutRef = useRef(null); // Referencia para controlar el timeout

	useEffect(() => {
		const profile = authClient.getProfile();
		setProfile(profile);
		getSalesAgents();
	}, []);

	useEffect(() => {
		if (userToken !== '') {
			//dispatch(fetchTaskList(undefined, userToken));
			dispatch(updateNotificationBoolean(true));
			setValue(1);
		}
	}, [userToken]);

	const getTaskFilter = () => {
		let filteredTasks =
			filterDate !== '' && allTaskWeek.length !== tasksObj.totalItems
				? allTaskWeek
				: tasksObj.tasks;
		if (filterType !== null && filterType !== '') {
			filteredTasks = tasksObj.tasks.filter((data) => data.type === filterType);
		}

		if (filterTaskStage !== '' && filterTaskStage !== null) {
			filteredTasks = filteredTasks.filter((data) => data?.operation?.stage === filterTaskStage);
		}

		if (filterTaskType !== '' && filterTaskType !== null) {
			filteredTasks = filteredTasks.filter((data) => data.type !== filterTaskType);
		}

		if (filterPriority !== '' && filterPriority !== null) {
			filteredTasks = filteredTasks.filter((data) => data.priority === filterPriority);
		}
		if (filterAsigneed === 'prerisk') {
			filteredTasks = filteredTasks.filter((data) =>
				filterDate !== ''
					? data.operation &&
					  data.operation.salesAgent &&
					  data.operation.salesAgent.type === filterAsigneed
					: data.operation &&
					  data.operation.riskManager &&
					  data.operation.riskManager.type === filterAsigneed
			);
		}
		if (filterAsigneed === 'No') {
			filteredTasks = filteredTasks.filter((data) =>
				filterDate !== ''
					? data.operation &&
					  data.operation.salesAgent &&
					  data.operation.salesAgent.type !== 'prerisk'
					: data.operation &&
					  data.operation.riskManager &&
					  data.operation.riskManager.type !== 'prerisk'
			);
		}
		if (filterDate !== '' && filterDate !== null) {
			filteredTasks = filteredTasks.filter(
				(data) =>
					moment(data.duedDate).format('YYYY/MM/DD') === moment(filterDate).format('YYYY/MM/DD')
			);
		}
		if (searchByDescription !== '' && searchByDescription !== null) {
			filteredTasks = filteredTasks.filter(
				(data) =>
					data.name.toLowerCase().includes(searchByDescription.toLowerCase()) ||
					data.description.toLowerCase().includes(searchByDescription.toLowerCase())
			);
		}

		tasksObj.tasks = filteredTasks;

		return tasksObj.tasks;
	};

	useEffect(() => {
		const cleanedSearchParams = Object.fromEntries(
			Object.entries(searchParams).filter(([key, value]) => value !== undefined && value !== null)
		);

		dispatch(fetchTaskList(new URLSearchParams(cleanedSearchParams), userToken));
	}, [searchParams]);

	const onSearch = (value, type) => {
		searchParams[type] = value;
		setSearchParams({ ...searchParams });
	};

	const onSearchText = (value, type) => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = setTimeout(() => {
			searchParams[type] = value;
			setSearchParams({ ...searchParams });
		}, 300);
	};

	const getSalesAgents = () => {
		axios
			.get(`${env.api.url}/v1/hadmin/sales-agents`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setAgentsRisk(response.data.data);
				dispatch(allAnalyst(response.data.data));
			});
	};

	const getTaskDetails = async (taskId) => {
		const response = await axios.get(`${env.api.url}/v1/hadmin/tasks/${taskId}`, {
			headers: { Authorization: `Bearer ${authClient.getToken()}` }
		});

		setTaskDetails(response.data.task);
		initDelete2.taskIdDete = response.data.task.id;
	};

	const handleclickFin = (type, taskId) => {
		getTaskDetails(taskId).then(() => {
			setShowModalActionFin(!showModalActionFin);
		});
	};

	const handleCancelDelete = (type, taskId) => {
		formFinish.setFieldsValue(initDelete);
		setShowModalActionDelete(!showModalActionDelete);
	};

	const handleButtonDelete = (type, taskId) => {
		setShowModalActionDelete(!showModalActionDelete);
	};

	const handleOkAgendar = (type, taskId) => {
		getTaskDetails(taskId).then(() => {
			setShowModalActionAsing(!showModalActionAsing);
		});
	};

	const sendBank = (taskId, task) => {
		getBankRequest(task.data.bankRequestId);

		getTaskDetails(taskId).then(() => {
			setVisibleConfirmComponent(!visibleConfirmComponent);
		});
	};

	const onFinish = async (values) => {};
	const showModalEditFuction = async (taskId) => {
		getTaskDetails(taskId).then(() => {
			setShowModalEdit(!showModalEdit);
		});
	};

	const data = [
		{
			name: 'Inicio',
			link: '/'
		}
	];

	const handleShowModal = () => {
		setShowModalCreate(!showModalCreate);
	};
	let a = 10;
	let b = 0;

	const handleChange = (page, value) => {
		let valueTMP = 10;
		if (value > 10) {
			valueTMP = value;
		}
		setPage(page);

		searchParams.page = page;
		searchParams.limit = valueTMP;
		const cleanedSearchParams = Object.fromEntries(
			Object.entries(searchParams).filter(([key, value]) => value !== undefined && value !== null)
		);
		dispatch(fetchTaskList(new URLSearchParams(cleanedSearchParams), userToken));

		//dispatch(fetchTaskList(`page=${page}&limit=${valueTMP}`, userToken));
	};

	const renderTypeTask = (task) => {
		let type = undefined;

		if (task.type === 'call') {
			type = 'Llamar';
		} else if (task.type === 'doc') {
			type = 'Pedir documentación';
		} else if (task.type === 'other') {
			type = 'Otro';
		} else if (task.type === 'comdoc') {
			type = 'Compromiso de documentación';
		} else if (task.type === 'crtanalysis') {
			type = 'Certificado a analisis';
		} else if (task.type === 'tag') {
			type = 'Etiquetar';
		} else if (task.type === 'claim') {
			type = 'Reclamar documentación';
		} else if (task.type === 'claimoffer') {
			type = 'Reclamar Oferta';
		} else if (task.type === 'firstcall') {
			type = 'Primera llamada';
		} else if (task.type === 'followup') {
			type = 'Llamada de seguimiento';
		} else if (task.type === 'customerqualification') {
			type = 'Cualificación de cliente';
		} else if (task.type === 'phpanalysis') {
			type = 'Promotor Analisis';
		} else if (task.type === 'sendbanks') {
			type = 'Enviar a bancos';
		} else if (task.type === 'choosenoffer') {
			type = 'Selección de oferta';
		} else if (task.type === 'analysis') {
			type = 'Nueva operación en analisis';
		} else if (task.type === 'gibobsappraisal') {
			type = 'Tasacion con Gibobs';
		} else {
			type = 'Reclamar tasación';
		}

		return type;
	};

	const renderNotes = (task) => {
		let notesValues = [];

		task.notes.map((note, index) => {
			index = index + 1;
			notesValues.push(
				<>
					<Text style={{ color: 'white', fontSize: 12, fontWeight: 400 }}>
						{index + '. ' + note.body}{' '}
					</Text>
					<br />
				</>
			);
		});
		return notesValues;
	};

	const handleSubmitForm = (values, id) => {
		const objAsigment = {
			bankRequestId: bankRequest.id,
			bankAccountData: {
				name: values.name === undefined ? '' : values.name,
				email: values.email,
				phone: values.phone === undefined ? '' : values.phone,
				office: values.office === undefined ? '' : values.office,
				reference: values.reference === undefined ? '' : values.reference
			}
		};
		axios
			.post(`${env.api.url}/banks/bank-request/assign-to-account-executive`, objAsigment, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setVisible(!visible);
			});
	};

	const getBankRequest = (id) => {
		axios
			.get(`${env.api.url}/banks/bank-request/find-by-id/${id}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setBankRequest(response.data.data);

				if (
					_.isNil(response.data.data?.bankAccountData) ||
					_.isNil(response.data.data?.bankAccountData.email)
				) {
					setVisible(!visible);
				} else {
					setVisibleConfirmComponent(!visibleConfirmComponent);
				}
			});
	};

	const createClaim = () => {
		axios
			.post(
				`${env.api.url}/banks/bank-request-claim/claim-offer`,
				{
					bankRequestId: bankRequest.id,
					operationId: bankRequest.operationId
				},

				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				setVisibleConfirm(!visibleConfirm);

				dispatch(fetchTaskList(undefined, userToken));
			});
	};

	const renderTo = (task) => {
		if (task.operationName !== null) {
			if (task.type !== 'crtanalysis' && task?.operation?.type !== 'php') {
				return `/operations/mortgages/${
					task.operationId === undefined ? task.operation && task.operation.id : task.operationId
				}`;
			} else if (task.type !== 'crtanalysis' && task?.operation?.type === 'php') {
				return `/promoter-loan/operations/${
					task.operationId === undefined ? task.operation && task.operation.id : task.operationId
				}`;
			} else {
				return `/certificates/list/basic/data-basic/${
					task.operationId === undefined ? task.operation && task.operation.id : task.operationId
				}`;
			}
		} else {
			return `/operations/mortgages/${
				task.operationId === undefined ? task.operation && task.operation.id : task.operationId
			}`;
		}
	};

	const renderDescription = (text) => {
		text = text.replace(/\n/g, '<br />');

		return text;
	};

	return (
		<Layout className="page-mortgages">
			<Content
				style={{
					padding: '24px 40px',
					minHeight: 280
				}}>
				<MyBreadcrumb data={data}></MyBreadcrumb>
				<CalendarComponent
					allTask={getTaskFilter()}
					allTaskWeek={allTaskWeek && allTaskWeek}
					days={days}
					currentDate={currentDate}
					setCurrentDate={(e) => {
						setCurrentDate(e);
						setFilterDate(moment(e).format('YYYY/MM/DD'));
					}}
				/>
				<Content style={{ padding: '24px 0', minHeight: 280 }}>
					<Card className="CardTaskGeneral" style={{ marginBottom: 200 }}>
						<Row gutter={24} style={{ marginTop: -10 }}>
							<Col span={4}>
								<div style={{ fontSize: 12, fontWeight: 400, marginRight: 8 }}>Buscar</div>
								<Input
									placeholder="Buscar"
									onChange={(e) => {
										setSearchByDescription(e.target.value);
										onSearchText(e.target.value, 'textSearch');
									}}
									style={{ borderColor: '#C0DAEE', height: 32, borderRadius: 4 }}
									suffix={<SearchOutlined />}
								/>
							</Col>
							<Col span={4}>
								<div style={{ fontSize: 12, fontWeight: 400, marginRight: 8 }}>
									Sin tarea de tipo
								</div>
								<Select
									style={{ width: '100%' }}
									value={filterTaskType}
									onChange={(e) => {
										setFilterTaskType(e);
										onSearch(e, 'withNoTaskType');
									}}>
									<Option value="comdoc">Compromiso de documentación</Option>
									<Option value="crtanalysis">Certificado a analisis</Option>
									<Option value="tag">Etiquetar</Option>
									<Option value="call">Llamar</Option>
									<Option value="doc">Pedir documentación</Option>
									<Option value="firstcall">Primera llamada</Option>
									<Option value="other">Otro</Option>
									<Option value="claim">Reclamar documentación</Option>
									<Option value="claimoffer">Reclamar Oferta</Option>
									<Option value="claimappraisal">Reclamar tasación</Option>
									<Option value="sendbanks">Enviar a bancos</Option>
									<Option value="choosenoffer">Selección de oferta</Option>
									<Option value="followup">LLamada de seguimiento</Option>
									<Option value="customerqualification">Cualificación de cliente</Option>
									<Option value="phpanalysis">Promotor analisis</Option>
									<Option value="analysis">Nueva operacion en analisis</Option>
									<Option value="gibobsappraisal">Tasación con gibobs</Option>
								</Select>
							</Col>
							<Col span={3}>
								<div style={{ width: 60, fontSize: 12, fontWeight: 400, marginRight: 8 }}>
									Etapa
								</div>
								<Select
									style={{ width: '100%', marginRight: 16 }}
									value={filterTaskStage}
									onChange={(e) => {
										onSearch(e, 'stage');
										setFilterTaskStage(e);
									}}>
									<Option value="basic">Basicos</Option>
									<Option value="documents">Documentos</Option>
									<Option value="analysis">Analisis</Option>
									<Option value="banks">Bancos</Option>
									<Option value="oferts">Ofertas</Option>
									<Option value="appraisal">Tasacion</Option>
									<Option value="formalization">Formalizacion</Option>
								</Select>
							</Col>
							<Col span={2}>
								<div style={{ width: 60, fontSize: 12, fontWeight: 400, marginRight: 8 }}>
									Asignado
								</div>
								<Select
									style={{ width: '100%', marginRight: 16 }}
									value={filterAsigneed}
									onChange={(e) => {
										onSearch(e, 'isAssignedToPrerisk');
										setFilterAsigneed(e);
									}}>
									<Option value={undefined}>Todos</Option>
									<Option value={true}>Si</Option>
									<Option value={false}>No</Option>
								</Select>
							</Col>
							<Col span={6}>
								<div style={{ width: 60, fontSize: 12, fontWeight: 400, marginRight: 8 }}>
									Tipo tarea
								</div>
								<Select
									value={filterType}
									onChange={(e) => {
										onSearch(e, 'type');
										setFilterType(e);
									}}
									style={{ width: '100%', marginRight: 16 }}>
									<Option value={undefined} key={undefined}>
										Todos
									</Option>
									<Option value="comdoc">Compromiso de documentación</Option>
									<Option value="crtanalysis">Certificado a analisis</Option>
									<Option value="tag">Etiquetar</Option>
									<Option value="call">Llamar</Option>
									<Option value="doc">Pedir documentación</Option>
									<Option value="firstcall">Primera llamada</Option>
									<Option value="other">Otro</Option>
									<Option value="claim">Reclamar documentación</Option>
									<Option value="claimoffer">Reclamar Oferta</Option>
									<Option value="claimappraisal">Reclamar tasación</Option>
									<Option value="sendbanks">Enviar a bancos</Option>
									<Option value="choosenoffer">Selección de oferta</Option>
									<Option value="followup">LLamada de seguimiento</Option>
									<Option value="customerqualification">Cualificación de cliente</Option>
									<Option value="phpanalysis">Promotor analisis</Option>
									<Option value="analysis">Nueva operacion en analisis</Option>
									<Option value="gibobsappraisal">Tasación con gibobs</Option>
								</Select>
							</Col>
							<Col span={2}>
								<div style={{ width: 60, fontSize: 12, fontWeight: 400, marginRight: 8 }}>
									Prioridad
								</div>
								<Select
									style={{ width: 90, marginRight: 16 }}
									value={filterPriority}
									onChange={(e) => {
										onSearch(e, 'priority');
										setFilterPriority(e);
									}}>
									<Option value={undefined}>Todos</Option>
									<Option value={30}>Alta</Option>
									<Option value={20}>Normal</Option>
									<Option value={10}>Baja</Option>
								</Select>
							</Col>

							<Col span={1} style={{ textAlign: 'right', marginTop: 20 }}>
								<Button
									type="primary"
									className="button-primari-gibobs"
									style={{ height: 32, width: 120, fontSize: 14 }}
									onClick={() => handleShowModal()}>
									Añadir tarea
								</Button>
							</Col>
						</Row>

						{value !== 0 &&
							tasksObj?.tasks?.length > 0 &&
							getTaskFilter() &&
							tasksObj.tasks.map((task, i) => (
								<Card
									key={`${task.id}-${i}`}
									className="CardTaskOnly"
									style={{
										marginTop: 16
									}}>
									<Row gutter={24}>
										<Col lg={14} md={14} sm={14} xs={24}>
											{task.priority === 30 && (
												<ArrowUpOutlined
													style={{ color: '#FF4D4F', marginRight: 10, fontSize: 16 }}
												/>
											)}
											{task.priority === 20 && (
												<PauseOutlined
													rotate={90}
													style={{ color: '#FAAD14', marginRight: 10, fontSize: 16 }}
												/>
											)}
											{task.priority === 10 && (
												<ArrowDownOutlined
													style={{ color: '#52C41A', marginRight: 10, fontSize: 16 }}
												/>
											)}

											<Text
												style={{
													fontSize: '16px',
													fontWeight: '700',
													color: '#2F4858',
													cursor: 'pointer'
												}}
												onClick={() => showModalEditFuction(task.id)}>
												{task.name}
											</Text>

											<NavLink to={renderTo(task)}>
												{task?.operation !== 'certificate' ? (
													task?.operation?.type === 'php' ? (
														<Text
															style={{
																fontSize: '14px',
																fontWeight: '700',
																color: '#02C3CD',
																marginLeft: '8px'
															}}>
															{task?.operation?.subname}
														</Text>
													) : (
														<Text
															style={{
																fontSize: '14px',
																fontWeight: '700',
																color: '#02C3CD',
																marginLeft: '8px'
															}}>
															{' '}
															{task.operationName}
														</Text>
													)
												) : (
													<Text
														style={{
															fontSize: '14px',
															fontWeight: '700',
															color: '#02C3CD',
															marginLeft: '8px'
														}}>
														{' '}
														{task?.operation.subname}
													</Text>
												)}
											</NavLink>

											<Text
												style={{
													fontSize: '14px',
													fontWeight: '600',
													color: '#748EA0',
													marginLeft: '8px'
												}}>
												{task &&
													task.operation &&
													task.operation.user &&
													task.operation.user.name +
														' ' +
														task.operation.user.surname +
														' ' +
														task.operation.user.phone}
											</Text>
										</Col>

										<Col lg={10} md={10} sm={10} xs={24} style={{ textAlign: 'right' }}>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '400',
													color: '#2F4858'
												}}>
												{'Tipo: '}
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '400',
													color: '#02C3CD'
												}}>
												{renderTypeTask(task)}
											</Text>

											<Text
												style={{
													fontSize: '12px',
													fontWeight: '400',
													color: '#2F4858',
													marginLeft: 8
												}}>
												{'Creada por: '}
											</Text>
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '400',
													color: '#02C3CD'
												}}>
												{task.reporterName}
											</Text>

											<Text
												style={{
													fontSize: '12px',
													fontWeight: '600',
													color: '#2F4858',
													marginLeft: 8
												}}>
												{'Vence el: ' + moment(task.duedDate).format('DD/MM/YYYY-HH:mm')}
											</Text>
											<BellOutlined style={{ color: '#2F4858', marginLeft: 8 }} />
										</Col>
									</Row>

									<Row gutter={[24, 16]} style={{ marginTop: 10 }}>
										<Col lg={18} md={18} sm={18} xs={24}>
											<div
												style={{
													fontSize: '12px',
													fontWeight: '400',
													color: '#2F4858'
												}}
												dangerouslySetInnerHTML={{
													__html: renderDescription(task.description)
												}}></div>
										</Col>
										<Col lg={6} md={6} sm={6} xs={24} style={{ textAlign: 'right' }}>
											{task.type === 'claimoffer' && (
												<Button
													className="button-secundary-gibobs"
													//onClick={() => getBankRequest(task.data.bankRequestId)}
													onClick={() => sendBank(task.id, task)}
													style={{
														width: 'auto',
														height: 24,
														marginTop: 'auto',
														fontSize: 12,
														fontWeight: 500
													}}>
													Reclamar a banco
												</Button>
											)}
											<CheckOutlined
												style={{
													color: '#02C3CD',
													// eslint-disable-next-line max-lines
													fontSize: '14px',
													marginRight: '9px',
													marginLeft: 12
												}}
												onClick={() => handleclickFin('complete', task.id)}
											/>
											<CalendarOutlined
												style={{
													color: '#02C3CD',
													fontSize: '14px',
													marginRight: '9px'
												}}
												onClick={() => handleOkAgendar('postpone', task.id)}
											/>
											{/* <Tooltip placement="left" title={renderNotes(task)}> */}
											<Text
												style={{
													fontSize: '12px',
													fontWeight: '400',
													color: '#02C3CD'
												}}>
												({task.attempts})
											</Text>
											{/* </Tooltip> */}
										</Col>
									</Row>
								</Card>
							))}
						{value !== 0 && tasksObj?.tasks?.length > 0 && (
							<Pagination
								current={page}
								total={tasksObj.totalItems}
								defaultPageSize={10}
								showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
								onChange={(a, b) => handleChange(a, b)}
								style={{ marginTop: '30px', float: 'right' }}
								showSizeChanger
							/>
						)}

						{value !== 0 && tasksObj?.tasks?.length === 0 && (
							<div style={{ textAlign: 'center' }}>
								<img src="/images/EmployeeOfTheMonth.png" alt="EmployeeOfTheMonth" />
							</div>
						)}
					</Card>
					{/* Modal para crear tareas */}
					<ModalCreateTasks
						// key={showModalCreate}
						visible={showModalCreate}
						width={800}
						allTask={tasksObj?.tasks}
						operationIdModal={operationIdModal}
						profile={profile}
						agentsRisk={agentsRisk}
						getTask={() => {
							dispatch(fetchTaskList(undefined));
							dispatch(fetchTaskPerWeeks(days));
							dispatch(updateNotificationBoolean(false));
						}}
						setShowModalEdit={(e) => setShowModalCreate(e)}
						initialValuesStatus={{
							operation: tasksObj?.tasks !== undefined ? tasksObj?.tasks.name : '',
							operationId: tasksObj?.tasks !== undefined ? tasksObj?.tasks.id : '',
							assigned: tasksObj?.tasks !== undefined ? tasksObj?.tasks.risk : '',
							hour: moment('21:00', 'HH:mm'),
							priority: 20,
							radiogroup: 1
						}}
					/>
					{/* Modal para editar tareas */}
					<ModalCreateTasks
						// key={showModalEdit}
						visible={showModalEdit}
						width={800}
						allTask={tasksObj?.tasks}
						operationIdModal={operationIdModal}
						profile={profile}
						agentsRisk={agentsRisk}
						getTask={() => {
							dispatch(fetchTaskList(undefined));
							dispatch(fetchTaskPerWeeks(days));
							dispatch(updateNotificationBoolean(false));
						}}
						setShowModalEdit={(e) => setShowModalEdit(e)}
						initialValuesStatus={{
							...taskDetails,
							date: moment(taskDetails && taskDetails.duedDate),
							radiogroup: 2,
							operation: taskDetails && taskDetails.operationName,
							assigned: taskDetails && taskDetails.assigneeName,
							esto: taskDetails && taskDetails.notification,
							hour: dayjs(taskDetails && taskDetails.duedDate)
						}}
						edit={true}
					/>
					{/* Modal para finalizar tareas */}
					<ModalFinishTask
						key={showModalActionFin}
						visible={showModalActionFin}
						setShowModalActionFin={(e) => setShowModalActionFin(e)}
						initialValuesStatus={{ taskIdDete: taskDetails && taskDetails.id }}
						getTask={() => {
							dispatch(fetchTaskList(undefined));
							dispatch(fetchTaskPerWeeks(days));
							dispatch(updateNotificationBoolean(false));
						}}
					/>
					{/* Modal para reagendar tareas */}
					<ModalReScheduleTask
						key={showModalActionAsing}
						visible={showModalActionAsing}
						setShowModalActionAsing={(e) => setShowModalActionAsing(e)}
						initialValuesStatus={{
							...taskDetails,
							taskIdDete: taskDetails && taskDetails.id,
							hour: dayjs(taskDetails && taskDetails.duedDate),
							date: dayjs(taskDetails && taskDetails.duedDate),
							radiogroup: taskDetails && taskDetails.duedDate ? 2 : 1
						}}
						getTask={() => {
							dispatch(fetchTaskList(undefined));
							dispatch(fetchTaskPerWeeks(days));
							dispatch(updateNotificationBoolean(false));
						}}
					/>

					<Modal
						className="gb-modal-style-common"
						cancelButtonProps={{ className: 'button-secundary-gibobs' }}
						okButtonProps={{ className: 'button-primari-gibobs' }}
						title="Eliminar tarea"
						open={showModalActionDelete}
						onOk={() => {
							formDelete
								.validateFields()
								.then((values) => {
									if (values.taskIdDete === undefined) {
										values.taskIdDete = taskDetails.id;
									}
									handleButtonDelete(values);
									dispatch(actions('delete', values.taskIdDete, values.note));
									formDelete.setFieldsValue(initDelete);
								})
								.catch((info) => {
									console.log('Validate Failed:', info);
								});
						}}
						okText={'Eliminar'}
						cancelText={'Cancelar'}
						onCancel={handleCancelDelete}
						closable={false}>
						<Form
							form={formDelete}
							layout="vertical"
							name="basic"
							onFinish={onFinish}
							style={{ display: 'flex' }}
							initialValues={{
								taskIdDete: taskDetails && taskDetails.id
							}}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<div style={{ display: 'flex', marginBottom: -30 }}>
									<Form.Item
										label="Nota:"
										name="note"
										rules={[{ required: true, message: 'Introduzca una nota' }]}
										style={{ float: 'left', marginRight: 50 }}>
										<TextArea
											style={{
												height: 32,
												height: 100,
												width: 350,
												marginBottom: 30
											}}
										/>
									</Form.Item>
								</div>
								<Form.Item
									hidden={true}
									name="taskIdDete"
									style={{ float: 'left', marginRight: 200, width: 150 }}>
									<Input hidden={true}></Input>
								</Form.Item>
							</div>
						</Form>
					</Modal>

					<Modal
						open={visible}
						className="gb-modal-style-common ModalCommonSendMessage"
						cancelButtonProps={{
							className: 'button-secundary-gibobs',
							style: { height: 32, width: 96 }
						}}
						okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
						title="Registrar asignación"
						okText="Aceptar"
						cancelText="Cancelar"
						width={496}
						onCancel={() => setVisible(!visible)}
						onOk={() => {
							form
								.validateFields()
								.then((values) => {
									handleSubmitForm(values, bankRequest.id);
									form.resetFields();
								})
								.catch((info) => {
									console.log('Validate Failed:', info);
								});
						}}>
						<div>
							<Text style={{ fontSize: 12, color: '#2F4858', fontWeight: 400 }}>
								El ejecutivo de cuentas no está registrado todavía. Por favor, introduce sus datos
								para poder asignarle esta operación.
							</Text>
						</div>

						<Form
							form={form}
							layout="vertical"
							name="normal_login"
							className="login-form"
							style={{ marginTop: 16 }}>
							<Row gutter={[16, 16]}>
								<Col span={12}>
									<Form.Item
										className="inputFormAnswers"
										name="name"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Nombre y Apellidos'}
											</Text>
										}>
										<Input placeholder="Nombre y Apellidos" />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										className="inputFormAnswers"
										name="email"
										rules={[
											{
												required: true
											}
										]}
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Email'}
											</Text>
										}>
										<Input type="email" placeholder="Email" />
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={[16, 24]} style={{ marginBottom: -15, marginTop: -12 }}>
								<Col span={12}>
									<Form.Item
										className="inputFormAnswers"
										name="phone"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Telefono'}
											</Text>
										}>
										<Input type="number" placeholder="Telefono de contacto" />
									</Form.Item>
								</Col>

								<Col span={12}>
									<Form.Item
										className="inputFormAnswers"
										name="office"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Oficina/Sucursal'}
											</Text>
										}>
										<Input placeholder="Información de oficina/sucursal" />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[16, 24]} style={{ marginBottom: -15, marginTop: -12 }}>
								<Col span={24}>
									<Form.Item
										className="inputFormAnswers"
										name="reference"
										label={
											<Text style={{ color: '#2F4858', fontSize: 10, fontWeight: 400 }}>
												{'Referencia'}
											</Text>
										}>
										<Input placeholder="Referencia del banco" />
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</Modal>

					<Modal
						className="gb-modal-style-common"
						cancelButtonProps={{
							className: 'button-secundary-gibobs',
							style: { height: 32, width: 96 }
						}}
						okButtonProps={{ className: 'button-primari-gibobs', style: { height: 32, width: 96 } }}
						title={
							<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
								{'Reclamación de oferta'}
							</Text>
						}
						closable={true}
						open={visibleConfirm}
						onOk={() => createClaim()}
						okText={'Si'}
						cancelText={'No'}
						onCancel={() => setVisibleConfirm(!visibleConfirm)}
						width={400}>
						<div style={{ marginTop: 0, marginBottom: 10 }}>
							<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
								¿Estás seguro que quieres enviar una reclamacion al banco?
							</Text>
						</div>
					</Modal>

					{visibleConfirmComponent && taskDetails && bankRequest && (
						<ModalsIncludesComponent
							bankRequestId={taskDetails?.data?.bankRequestId}
							idBank={bankRequest?.bankId}
							bankRequest={bankRequest}
							mortgageId={taskDetails?.operationId}
							visibleConfirm={visibleConfirmComponent}
							setVisibleConfirm={setVisibleConfirmComponent}
							mortgageName={taskDetails?.operationName}
							notClaim={true}></ModalsIncludesComponent>
					)}
				</Content>
			</Content>
		</Layout>
	);
}

export default Dashboard;
