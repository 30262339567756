import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Col,
	Row,
	Modal,
	Form,
	Select,
	InputNumber,
	Input,
	Spin,
	notification
} from 'antd';
import env from '../../../../../../environment';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import { InputDate } from 'afrodita';

const { Text } = Typography;
const { Option } = Select;

const TableCaixaBankApi = ({ mortgageId, mortgage, type }) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const location = useLocation();
	const bankRequestId =
		location && location.state !== undefined ? location.state.bankRequestId : undefined;
	const bankRequest =
		location && location.state !== undefined ? location.state.bankRequest : undefined;
	const t1Loan = _.get(mortgage, 'owner1.loanPayment', 0);
	const t1MortgagePay = _.get(mortgage, 'owner1.mortgagePayment', 0);
	const t1Pension = _.get(mortgage, 'owner1.pensionPayment', 0);
	const t2MortgagePay = _.get(mortgage, 'owner2.loanPayment', 0);
	const t2Loan = _.get(mortgage, 'owner2.mortgagePayment', 0);
	const t2Pension = _.get(mortgage, 'owner2.pensionPayment', 0);
	const totalPay1 = t1Loan + t1MortgagePay + t1Pension;
	const totalPay2 = t2Loan + t2MortgagePay + t2Pension;
	const [visibleCancel, setVisibleCancel] = useState(false);
	const [dataCaixa, setDataCaixa] = useState(undefined);
	const [loading, setLoading] = useState(false);
	const [emptyFields, setEmptyFields] = useState([]);

	const activityTypesToCaixaTypes = [
		{ code: '01', name: 'EMPLOYEE_FIXED', label: 'Empleado indefinido' },
		{ code: '02', name: 'EMPLOYEE_TEMPORAL', label: 'Empleado temporal' },
		{ code: '03', name: 'FREELANCER', label: 'Autónomo' },
		{ code: '05', name: 'UNEMPLOYED', label: 'Desempleado' },
		{ code: '06', name: 'FUNCTIONARY', label: 'Funcionario' },
		{ code: '07', name: 'UNKNOW', label: 'Desconocido' },
		{ code: '07', name: 'PENSIONER', label: 'Jubilado' },
		{ code: '07', name: 'BUSINESS_OWNER', label: 'Empresario' },
		{ code: '07', name: 'RENTIER', label: 'Rentista' },
		{ code: '06', name: 'FUNCTIONARY_INTERIM', label: 'Funcionario interino' }
	];

	useEffect(() => {
		axios
			.get(`${env.api.url}/banks/caixa/data`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setDataCaixa(response.data.data);
			});
	}, []);

	useEffect(() => {
		if (dataCaixa && mortgage) {
			handleFormChange();
		}
	}, [form, dataCaixa, mortgage]);

	const processPhoneNumber = (phoneNumber, property) => {
		if (!phoneNumber) return undefined;

		let prefix = '34';
		let number = phoneNumber;
		if (phoneNumber.startsWith('+')) {
			prefix = phoneNumber.substring(1, 3);
			number = phoneNumber.substring(3);
		}
		return property === 'prefix' ? prefix : number;
	};

	const renderStateCode = (province) => {
		const communities = {
			Almería: 'ES-AN',
			Cádiz: 'ES-AN',
			Córdoba: 'ES-AN',
			Granada: 'ES-AN',
			Huelva: 'ES-AN',
			Jaén: 'ES-AN',
			Málaga: 'ES-AN',
			Sevilla: 'ES-AN',
			Huesca: 'ES-AR',
			Teruel: 'ES-AR',
			Zaragoza: 'ES-AR',
			Asturias: 'ES-AS',
			Baleares: 'ES-IB',
			'Las Palmas': 'ES-CN',
			'Santa Cruz de Tenerife': 'ES-CN',
			Cantabria: 'ES-CB',
			Albacete: 'ES-CM',
			'Ciudad Real': 'ES-CM',
			Cuenca: 'ES-CM',
			Guadalajara: 'ES-CM',
			Toledo: 'ES-CM',
			Ávila: 'ES-CL',
			Burgos: 'ES-CL',
			León: 'ES-CL',
			Palencia: 'ES-CL',
			Salamanca: 'ES-CL',
			Segovia: 'ES-CL',
			Soria: 'ES-CL',
			Valladolid: 'ES-CL',
			Zamora: 'ES-CL',
			Barcelona: 'ES-CT',
			Girona: 'ES-CT',
			Lleida: 'ES-CT',
			Tarragona: 'ES-CT',
			Ceuta: 'ES-CE',
			Alicante: 'ES-VC',
			Castellón: 'ES-VC',
			Valencia: 'ES-VC',
			Badajoz: 'ES-EX',
			Cáceres: 'ES-EX',
			'A Coruña': 'ES-GA',
			Lugo: 'ES-GA',
			Ourense: 'ES-GA',
			Pontevedra: 'ES-GA',
			Madrid: 'ES-MD',
			Melilla: 'ES-ML',
			Murcia: 'ES-MC',
			Navarra: 'ES-NC',
			Álava: 'ES-PV',
			Guipúzcoa: 'ES-PV',
			Vizcaya: 'ES-PV',
			'La Rioja': 'ES-RI'
		};

		return communities[province] || 'Seleccionar';
	};

	const renderForm = () => {
		const arrayform = [];

		for (let index = 1; index <= mortgage.mortgage.participants; index++) {
			arrayform.push(
				<>
					<Text>{'Titular ' + index}</Text>
					<Row gutter={24} style={{ marginTop: 12, marginBottom: 12 }}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`name${index}`}
								label="Nombre"
								className={emptyFields.includes(`name${index}`) ? 'shadowed' : ''}
								rules={[
									{
										required: true,
										validator: (e, value, callback) => {
											return new Promise((resolve, reject) => {
												if (value.length > 20) {
													reject('Debe ser menor o igual a 20 caracteres');
												} else {
													resolve();
												}
											});
										}
									}
								]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>

						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`lastName${index}`}
								label={'Apellido'}
								className={emptyFields.includes(`lastName${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`birthdate${index}`}
								label="Fecha de nacimiento"
								className={emptyFields.includes(`birthdate${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<InputDate style={{ width: '100%', borderRadius: 4 }} type="date" />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`type${index}`}
								label="Tipo de documento"
								className={emptyFields.includes(`type${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Select showSearch placeholder="Seleccione" style={{ width: '100%' }}>
									{dataCaixa.documentIdType.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`customerId${index}`}
								label="DNI"
								className={emptyFields.includes(`customerId${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`issuingCountry${index}`}
								label="País"
								className={emptyFields.includes(`issuingCountry${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Select showSearch placeholder="Seleccione" style={{ width: '100%' }}>
									{dataCaixa.countries.map((data) => (
										<Option key={data.isoCode3} value={data.isoCode3}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`prefix${index}`}
								label="Prefijo tlf: 34 (sin el +)"
								className={emptyFields.includes(`prefix${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Input placeholder="34" style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`number${index}`}
								label="Número tlf"
								className={emptyFields.includes(`number${index}`) ? 'shadowed' : ''}
								rules={[
									{ required: true, message: 'Por favor, completa este campo' },
									({ getFieldValue }) => ({
										validator(_, value) {
											if (value && value.toString().length > 9) {
												return Promise.reject(
													new Error('El teléfono no puede tener más de 9 dígitos')
												);
											}
											return Promise.resolve();
										}
									})
								]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`address${index}`}
								label="Email"
								className={emptyFields.includes(`address${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`annualIncomes${index}`}
								label="Ingresos anuales"
								className={emptyFields.includes(`annualIncomes${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`contractType${index}`}
								label="Tipo de contrato"
								className={emptyFields.includes(`contractType${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Select showSearch placeholder="Seleccione" style={{ width: '100%' }}>
									{dataCaixa?.employmentContract.map((data) => (
										<Option key={data.code} value={data.name}>
											{data.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`currentBalance${index}`}
								label="Deuda total"
								className={emptyFields.includes(`currentBalance${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={`installment${index}`}
								label="Deuda mensual"
								className={emptyFields.includes(`installment${index}`) ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
					</Row>
				</>
			);
		}

		return arrayform;
	};

	const onFinish = (values) => {
		const owners = [];
		for (let index = 1; index <= mortgage.mortgage.participants; index++) {
			owners.push({
				name: values[`${'name' + index}`],
				lastName: values[`${'lastName' + index}`],
				birthdate: moment(values[`${'birthdate' + index}`], 'DD-MM-YYYY').format('YYYY-MM-DD'),
				documentId: {
					type: values[`${'type' + index}`],
					customerId: values[`${'customerId' + index}`],
					issuingCountry: values[`${'issuingCountry' + index}`]
				},
				contact: {
					phone: [
						{
							prefix: values[`${'prefix' + index}`],
							number: parseInt(values[`${'number' + index}`])
						}
					],
					email: [
						{
							address: values[`${'address' + index}`]
						}
					]
				},
				incomes: {
					annualIncomes: {
						ccy: 'EUR',
						amount: values[`${'annualIncomes' + index}`]
					},
					contractType:
						dataCaixa?.employmentContract.find(
							(data) => data.name === values[`${'contractType' + index}`]
						).code || values[`${'contractType' + index}`]
				},
				debts: [
					{
						currentBalance: {
							ccy: 'EUR',
							amount: values[`${'currentBalance' + index}`]
						},
						installment: {
							ccy: 'EUR',
							amount: values[`${'installment' + index}`]
						}
					}
				],
				residenceCountryCode: 'ESP',
				requester: index === 1 ? true : false
			});
		}

		const dataMortgage = {
			mortgage: {
				operationType: values.operationType,
				economicData: {
					buyingPrice: {
						ccy: 'EUR',
						amount: values.buyingPrice
					},
					loanAmount: {
						ccy: 'EUR',
						amount: values.loanAmount
					},
					savingsAmount: {
						ccy: 'EUR',
						amount: values.savingsAmount
					}
				},
				property: {
					usage: values.usage,
					type: values.type,
					address: {
						//countryCode: 'ESP', //poner como select
						//municipalityCode: values.municipalityCode.toString(),
						provinceCode: values.provinceCode,
						autonomousCommunityCode: values.autonomousCommunityCode,
						streetType: values.streetType,
						streetName: values.streetName,
						...(values.postCode && { postCode: values.postCode.toString() }),
						...(values.buildingNumber && { buildingNumber: values.buildingNumber }),
						...(values.stairNumber && { stairNumber: values.stairNumber }),
						...(values.floorNumber && { floorNumber: values.floorNumber }),
						...(values.apartmentNumber && { apartmentNumber: values.apartmentNumber })
					}
				},
				loanTerm: values.loanTerm,
				mortgagors: owners
			}
		};

		setLoading(true);
		axios
			.post(
				`${env.api.url}/banks/caixa/execute-process`,
				{
					bankRequestId: bankRequestId,
					mortgage: dataMortgage.mortgage
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				if (response.data.success) {
					setLoading(false);
					notification.success({
						message: 'Se ha generado correctamente el bank Resquest.',
						description: 'La bank Resquest se genero correctamente'
					});
					navigate(`/operations/mortgages/${mortgageId}/?tab=5`);
				} else {
					setLoading(false);
					notification.error({
						message: 'Error',
						description: 'Algun campo introducido esta mal reviselo'
					});
				}
			})
			.catch((error) => {
				setLoading(false);
				notification.error({
					message: 'Error',
					description: 'Algo fue mal'
				});
			});
	};

	const handleFormChange = () => {
		const values = form.getFieldsValue();
		const undefinedFields = Object.entries(values)
			.filter(
				([fieldName, value]) => value === undefined || value === '' || value === 'Fecha inválida'
			)
			.map(([fieldName]) => fieldName);
		setEmptyFields(undefinedFields);
	};
	const cancelSend = () => {
		setVisibleCancel(!visibleCancel);
	};
	const handleOk = () => {
		setVisibleCancel(!visibleCancel);
		navigate(`/operations/mortgages/${mortgageId}/form-send-bank`);
	};
	const handleCancel = () => {
		setVisibleCancel(!visibleCancel);
	};

	if (loading) {
		return (
			<div style={{ textAlign: 'center', height: '100vh' }}>
				<Spin style={{ position: 'absolute', top: '50%' }} size="large" />
			</div>
		);
	}

	return (
		<>
			<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858', marginTop: 24 }}>
				Datos de la operación
			</Text>

			{dataCaixa && mortgage && (
				<Form
					form={form}
					layout="vertical"
					className="gb-form-offers-new"
					name="sendMessageForm"
					style={{ width: '100%', marginTop: 24 }}
					onChange={handleFormChange}
					initialValues={{
						name1: mortgage?.owner1?.name,
						name2: mortgage?.owner2?.name,
						lastName1: mortgage?.owner1?.firstSurname,
						lastName2: mortgage?.owner2?.firstSurname,
						birthdate1: moment(mortgage?.owner1?.birthdate).format('DD-MM-YYYY'),
						birthdate2: moment(mortgage?.owner2?.birthdate).format('DD-MM-YYYY'),
						customerId1: mortgage?.owner1?.nif,
						customerId2: mortgage?.owner2?.nif,
						prefix1: processPhoneNumber(mortgage?.owner1?.phone, 'prefix'),
						prefix2: processPhoneNumber(mortgage?.owner2?.phone, 'prefix'),
						number1: processPhoneNumber(mortgage?.owner1?.phone, 'phoneNumber'),
						number2: processPhoneNumber(mortgage?.owner2?.phone, 'phoneNumber'),
						address1: mortgage?.owner1?.email,
						address2: mortgage?.owner2?.email,
						annualIncomes1:
							mortgage?.owner1?.netIncomes * mortgage?.owner1?.numberOfPayments +
							mortgage?.owner1?.bonus,
						annualIncomes2:
							mortgage?.owner2?.netIncomes * mortgage?.owner2?.numberOfPayments +
							mortgage?.owner2?.bonus,
						contractType1: activityTypesToCaixaTypes.find(
							(data) => data.name === mortgage?.owner1.employment.currentEmployments[0].activity
						).name,
						contractType2:
							mortgage.owner2 &&
							activityTypesToCaixaTypes.find(
								(data) => data.name === mortgage?.owner2?.employment.currentEmployments[0].activity
							).name,
						installment1: totalPay1,
						installment2: totalPay2,
						type1:
							mortgage?.owner1?.idDocumentType !== undefined
								? dataCaixa.documentIdType.find(
										(data) => data.name === mortgage?.owner1?.idDocumentType
								  ).code
								: undefined,
						type2:
							mortgage?.owner2 !== undefined && mortgage?.owner2?.idDocumentType
								? dataCaixa.documentIdType.find(
										(data) => data.name === mortgage?.owner2?.idDocumentType
								  ).code
								: undefined,
						issuingCountry1: mortgage?.owner1?.nationality === 'ES' ? 'ES' : undefined,
						issuingCountry2: mortgage?.owner2?.nationality === 'ES' ? 'ES' : undefined,
						operationType: type.subtype === 'mortgage' ? '01' : '03',
						buyingPrice: mortgage.propertyWishPurchase?.maxBudget,
						loanAmount: Number(bankRequest.amount),
						savingsAmount: Number(bankRequest.savings),
						usage:
							type.subtype === 'mortgage'
								? dataCaixa.propertyUsage.find(
										(data) => data.name === mortgage?.mortgage?.houseType
								  ).code
								: undefined,
						type:
							type.subtype === 'mortgage'
								? dataCaixa.propertyTypes.find(
										(data) => data.name === mortgage?.mortgage?.propertyType
								  ).code
								: undefined,
						postCode: mortgage?.propertyWishPurchase?.postalCode,
						provinceCode:
							type.subtype === 'mortgage'
								? dataCaixa.provinces.find(
										(data) => data.name === mortgage?.propertyWishPurchase?.province
								  ).code
								: undefined,
						autonomousCommunityCode:
							mortgage.subrogation !== undefined
								? renderStateCode(mortgage.subrogation.province)
								: renderStateCode(mortgage.mortgage.province),
						streetName: mortgage.mortgage.address,
						loanTerm: bankRequest?.months
					}}
					onFinish={onFinish}>
					{renderForm()}
					<Row gutter={24} style={{ marginTop: 24 }}>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'operationType'}
								label="Tipo de operacion"
								className={emptyFields.includes('operationType') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Select showSearch placeholder="Seleccione" style={{ width: '100%' }}>
									{dataCaixa?.operationTypes.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'buyingPrice'}
								label="Precio de compra"
								className={emptyFields.includes('buyingPrice') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'loanAmount'}
								label="Cantidad a financiar"
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'savingsAmount'}
								label="Ahorros"
								className={emptyFields.includes('savingsAmount') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<InputNumber style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'usage'}
								label="Tipo de uso"
								className={emptyFields.includes('usage') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Select
									showSearch
									placeholder="Seleccione"
									name="new/second"
									style={{ width: '100%' }}>
									{dataCaixa?.propertyUsage.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'type'}
								label="Tipo de vivienda"
								className={emptyFields.includes('type') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Select
									showSearch
									placeholder="Seleccione"
									name="new/second"
									style={{ width: '100%' }}>
									{dataCaixa?.propertyTypes.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						{/*<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'countryCode'}
								label='País'
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<InputNumber
									parser={(value) => numeral(value).value()}
									style={{ width: '100%', borderRadius: 4 }}
								/>
							</Form.Item>
							</Col>*/}
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'postCode'}
								label="Código postal"
								rules={[
									{
										validator: (_, value) => {
											if (value && value.toString().length > 5) {
												return Promise.reject('Debe ser menor o igual a 5 caracteres');
											}
											return Promise.resolve();
										}
									}
								]}>
								<InputNumber
									parser={(value) => numeral(value).value()}
									style={{ width: '100%', borderRadius: 4 }}
								/>
							</Form.Item>
						</Col>
						{/*<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'municipalityCode'}
								label='Muicipio CP'
								rules={[
									{
										required: true,
										validator: (e, value, callback) => {
											return new Promise((resolve, reject) => {
												if (value.length > 6) {
													reject('debe ser menor o igual a 6 caracteres');
												} else {
													resolve();
												}
											});
										}
									}
								]}>
								<InputNumber className="inputCommon" style={{ borderRadius: 4, width: '100%' }} />
							</Form.Item>
							</Col>*/}
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'provinceCode'}
								className={emptyFields.includes('provinceCode') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}
								label="Provincia">
								<Select showSearch placeholder="Seleccione" style={{ width: '100%' }}>
									{dataCaixa?.provinces.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'autonomousCommunityCode'}
								label="CP autónomo"
								className={emptyFields.includes('autonomousCommunityCode') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Select showSearch placeholder="Seleccione" style={{ width: '100%' }}>
									{dataCaixa?.autonomousCommunities.map((data) => (
										<Option key={data.code} value={data.code}>
											{data.label}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'streetType'}
								label="Tipo de calle"
								className={emptyFields.includes('streetType') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'streetName'}
								label="Nombre de la calle"
								className={emptyFields.includes('streetName') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>{' '}
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'buildingNumber'}
								label="Número de edificio">
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>{' '}
						<Col span={6}>
							<Form.Item style={{ width: '100%' }} name={'stairNumber'} label="Número de escalera">
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>{' '}
						<Col span={6}>
							<Form.Item style={{ width: '100%' }} name={'floorNumber'} label="Número de piso">
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'apartmentNumber'}
								label="Número de apartamento">
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item
								style={{ width: '100%' }}
								name={'loanTerm'}
								label="Plazo de préstamo"
								className={emptyFields.includes('loanTerm') ? 'shadowed' : ''}
								rules={[{ required: true, message: 'Por favor, completa este campo' }]}>
								<Input style={{ width: '100%', borderRadius: 4 }} />
							</Form.Item>
						</Col>
					</Row>

					<Row style={{ justifyContent: 'center', marginTop: 20 }}>
						<Button
							style={{ height: 32, width: 96, fontSize: 14 }}
							className="button-secundary-gibobs"
							onClick={() => cancelSend()}
							type="dashed">
							Cancelar
						</Button>

						<Button
							style={{ height: 32, marginLeft: 8, width: 96, fontSize: 14 }}
							type="primary"
							htmlType="submit"
							className="button-primari-gibobs">
							Aceptar
						</Button>
					</Row>
				</Form>
			)}
			<Modal
				className="gb-modal-style-common"
				cancelButtonProps={{
					className: 'button-secundary-gibobs',
					style: { width: 96, marginTop: 16 }
				}}
				okButtonProps={{ className: 'button-primari-gibobs', style: { width: 96, marginTop: 16 } }}
				visible={visibleCancel}
				title={
					<Text style={{ fontSize: '14px', fontWeight: '600px', color: '#2F4858' }}>
						{'Cancelar envio Caixa'}
					</Text>
				}
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				width={404}
				onCancel={handleCancel}
				onOk={handleOk}>
				<Text style={{ fontSize: '12px', fontWeight: '400px', color: '#2F4858' }}>
					{'¿Quieres salir del envio a Caixa?'}
				</Text>
			</Modal>
		</>
	);
};
export default TableCaixaBankApi;
